export function debounce(fn, delay = 500) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(fn.bind(this, ...args), delay);
  };
}

import emoji from "./emoji";

export default function directive(app) {
  app.directive("emoji", emoji);
}

export function loadBMap(ak) {
  return new Promise(function (resolve, reject) {
    if (typeof BMap !== "undefined") {
      resolve(BMap);
      return true;
    }
    window.onBMapCallback = function () {
      resolve(BMap);
    };
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://api.map.baidu.com/api?v=3.0&ak=" +
      ak +
      "&__ec_v__=20190126&callback=onBMapCallback";
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  return `${year}.${month}.${day}`;
}
export function getCurrentDateTime() {
  const now = new Date();
  const weekdays = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ];
  const dayOfWeek = weekdays[now.getDay()];

  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const h = now.getHours().toString().padStart(2, "0");
  const m = now.getMinutes().toString().padStart(2, "0");
  const s = now.getSeconds().toString().padStart(2, "0");

  return `${now.getFullYear()}年${month}月${day}日 ${dayOfWeek} ${h}:${m}:${s}`;
}

//#通过计算将百度经纬度转换为高德经纬度def
//g =lng   // t =lat
export function bMapTransQQMap(lng, lat) {
  let x_pi = (3.14159265358979324 * 3000.0) / 180.0;
  let x = lng - 0.0065;
  let y = lat - 0.006;
  let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
  let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
  let lngs = z * Math.cos(theta);
  let lats = z * Math.sin(theta);
  console.log("转换后", {
    lng: lngs,
    lat: lats,
  });
  return {
    lng: lngs,
    lat: lats,
  };
}
// 深拷贝对象
export function deepClone(obj) {
  const _toString = Object.prototype.toString;

  // null, undefined, non-object, function
  if (!obj || typeof obj !== "object") {
    return obj;
  }

  // DOM Node
  if (obj.nodeType && "cloneNode" in obj) {
    return obj.cloneNode(true);
  }

  // Date
  if (_toString.call(obj) === "[object Date]") {
    return new Date(obj.getTime());
  }

  // RegExp
  if (_toString.call(obj) === "[object RegExp]") {
    const flags = [];
    if (obj.global) {
      flags.push("g");
    }
    if (obj.multiline) {
      flags.push("m");
    }
    if (obj.ignoreCase) {
      flags.push("i");
    }

    return new RegExp(obj.source, flags.join(""));
  }

  const result = Array.isArray(obj)
    ? []
    : obj.constructor
    ? new obj.constructor()
    : {};

  for (const key in obj) {
    result[key] = deepClone(obj[key]);
  }

  return result;
}

export function makeMap(str, expectsLowerCase) {
  const map = Object.create(null);
  const list = str.split(",");
  for (let i = 0; i < list.length; i++) {
    map[list[i]] = true;
  }
  return expectsLowerCase ? (val) => map[val.toLowerCase()] : (val) => map[val];
}

//递归列表组成树形结构
export function buildTree(data) {
  let cloneData = JSON.parse(JSON.stringify(data)); // 对源数据深度克隆

  let tree = cloneData.filter((father) => {
    //循环所有项

    let branchArr = cloneData.filter((child) => {
      return father.id == child.pId; //返回每一项的子级数组
    });

    if (branchArr.length > 0) {
      father.children = branchArr; //如果存在子级，则给父级添加一个children属性，并赋值
    }

    return father.pId == 0; //返回第一层
  });

  return tree; //返回树形数据
}



