<!--
 * @Author: Lijiahui
 * @Date: 2020-10-19 22:04:38
 * @LastEditors: yuan wei lin
 * @LastEditTime: 2023-05-11 17:32:55
-->

<template>
  <div class="passwor_username_box">
    <!-- ref="ruleForm" -->
    <el-form
      :model="ruleForm"
      style="width: 100%"
      :rules="rules"
      ref="login_form"
      class="demo-ruleForm"
    >
      <!-- <el-col :span="20" :offset="0"> -->
      <el-form-item prop="name" rules="name">
        <el-input
          placeholder="账号"
          prefix-icon="Avatar"
          v-model="ruleForm.name"
        >
        </el-input>
      </el-form-item>
      <!-- </el-col> -->

      <!-- <el-col :span="20" :offset="0" style="position: relative"> -->
      <el-form-item prop="password" rules="password">
        <el-input
          :type="typepsd"
          placeholder="密码"
          prefix-icon="Lock"
          v-model="ruleForm.password"
        >
        </el-input>
        <img @click="handleclose" class="typepsd_img" :src="imgsrc" alt="" />
      </el-form-item>

      <!-- </el-col> -->

      <!--<el-row :gutter="20">-->
      <!--<el-col :span="13">-->
      <!--<el-form-item prop="number">-->
      <!--<el-input placeholder="验证码" v-model="ruleForm.number">-->
      <!--</el-input>-->
      <!--</el-form-item>-->
      <!--</el-col>-->
      <!--<el-col :span="7" >-->
      <!--<div class="grid-content bg-purple-light">-->
      <!--<img style="width: 100%;height:32px;cursor: pointer;margin-top:10px;" :src="imgUrl" alt="看不清？点击换一张" @click="changeImg" />-->
      <!--</div>-->
      <!--</el-col>-->
      <!--</el-row>-->
      <!-- <el-row> -->
      <!-- <el-col :span="18" :offset="2"> -->
      <div class="forgetPass">
        <el-checkbox v-model="forgetChecked">记住密码</el-checkbox>
        <!--<div class="forget" @click="handleForget">忘记密码</div>-->
      </div>
      <!-- </el-col> -->
      <!-- </el-row> -->
      <!-- <el-row> -->
      <!-- <el-col :span="18" :offset="2"> -->
      <el-button
        class="tabsPsd_btn"
        type="primary"
        @click="submitForm('ruleForm')"
        >登录</el-button
      >
      <!-- </el-col> -->
      <!-- </el-row> -->
    </el-form>

    <!-- <Verify @success="success" :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify> -->
  </div>

  <Verify
    mode="pop"
    :captchaType="'blockPuzzle'"
    :imgSize="{ width: '400px', height: '200px' }"
    ref="verify"
    @success="success"
  ></Verify>
</template>

<script>
import elTabsPassword from "./js/elTabsPassword";
export default {
  ...elTabsPassword,
};
</script>
<style lang='less' scoped>
@import "./css/elTabsPassword.less";
</style>
