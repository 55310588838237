<!--
 * @Author: 
 * @Date: 2023-09-21 15:27:02
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2023-09-21 16:08:51
 * @Description: 
-->
<template>
  <div class="dailog-model">
    <!-- <img src="../assets/img/loading.gif" /> -->
    <img src="../assets/img/0ba928a5dd454cdd937807d2981725d2.gif" />
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="less">
.dailog-model {
  // width: 100%;
  // height: 100%;
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: calc(100% - 230px - 10px);
  height: calc(100% - 50px - 28px - 10px);
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  > img {
    width: 48px;
    height: 48px;
  }
}
</style>
