/*
 * @Author: yuan wei lin
 * @Date: 2022-10-12 14:34:57
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2023-12-27 17:45:49
 * @Descripttion:
 */

export default {
  // 秒 转成 时分秒 (秒,Boolean)
  SecondToHourMinuteSecond(second, isMakeZero) {
    let result = "";
    let h, m, s;
    if (!second) return result;
    if (second > 60) {
      h = parseInt(second / 3600);
      m = parseInt((second / 60) % 60);
      s = Math.ceil(second % 60);
      if (isMakeZero) {
        m < 10 ? (m = `0${m}`) : m;
        s < 10 ? (s = `0${s}`) : s;
      }
      if (h) result += `${h}小时`;
      if (m) result += `${m}分钟`;
      if (s) result += `${s}秒`;
    } else result = `${second}秒`;
    return result;
  },

  // 获取当前日期
  GetCurrentDate(type) {
    let time = "";
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let h = date.getHours(); //获取当前小时数(0-23)
    let m = date.getMinutes(); //获取当前分钟数(0-59)
    let s = date.getSeconds(); //获取当前秒数(0-59)
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;
    if (type == "yyyy-mm-dd") time = `${year}-${month}-${day}`;
    if (type == "yyyy-mm-dd hh:mm:ss")
      time = `${year}-${month}-${day} ${h}:${m}:${s}`;
    return time;
  },

  GetCurrentDate2(type, targetTime) {
    type = String(type).toLocaleLowerCase();
    let time = "";
    let date;

    if (targetTime) date = new Date(targetTime);
    else date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let h = date.getHours(); //获取当前小时数(0-23)
    let m = date.getMinutes(); //获取当前分钟数(0-59)
    let s = date.getSeconds(); //获取当前秒数(0-59)
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;
    if (h < 10) h = `0${h}`;
    if (m < 10) m = `0${m}`;
    if (s < 10) s = `0${s}`;
    if (type == "yyyy-mm-dd hh:mm:ss")
      time = `${year}-${month}-${day} ${h}:${m}:${s}`;
    if (type == "yyyy-mm-dd hh:mm") time = `${year}-${month}-${day} ${h}:${m}`;
    if (type == "yyyy-mm-dd") time = `${year}-${month}-${day}`;
    if (type == "yyyy-mm") time = `${year}-${month}`;
    if (type == "mm-dd") time = `${month}-${day}`;
    if (type == "hh:mm:ss") time = `${h}:${m}:${s}`;
    if (type == "hh:mm") time = `${h}:${m}`;
    if (type == "mm:ss") time = `${m}:${s}`;
    return time;
  },
  // // 获取前几天或者后几天
  // getNextDate(date, day) {
  //   let dd = new Date(date);
  //   dd.setDate(dd.getDate() + day);
  //   console.log("111" + (dd.getDate() + day));
  //   let y = dd.getFullYear();
  //   let m =
  //     dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
  //   let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
  //   return y + "-" + m + "-" + d;
  // },

  /*获取一个月的天数 */
  getCountDays() {
    let curDate = new Date();
    let curMonth = curDate.getMonth() + 1;
    curDate.setMonth(curMonth);
    curDate.setDate(0);
    return curDate.getDate();
  },

  // 计算两个时间相差多少天
  GetTimeDifference(faultDat, completeTime) {
    let stime = Date.parse(new Date(faultDat));
    let etime = Date.parse(new Date(completeTime));
    let usedTime = etime - stime;
    let days = Math.floor(usedTime / (24 * 3600 * 1000));
    let leave1 = usedTime % (24 * 3600 * 1000);
    let h = Math.floor(leave1 / (3600 * 1000));
    let leave2 = leave1 % (3600 * 1000);
    let m = Math.floor(leave2 / (60 * 1000));
    // let time = days + "天" + h + "时" + m + "分";
    // return time;
    return days + 1;
  },

  // 获取当前周
  getCurrentWeek(dataTime) {
    //获取当前时间
    let currentDate = new Date(dataTime);
    //返回date是一周中的某一天
    let week = currentDate.getDay();
    //一天的毫秒数
    let millisecond = 1000 * 60 * 60 * 24;
    //减去的天数
    let minusDay = week != 0 ? week - 1 : 6;
    //本周 周一
    let monday = new Date(currentDate.getTime() - minusDay * millisecond);
    let startDateY = monday.getFullYear();
    let startDateM = monday.getMonth() + 1;
    let startDateD = monday.getDate();

    //本周 周日
    let sunday = new Date(monday.getTime() + 6 * millisecond);
    let endDateY = sunday.getFullYear();
    let endDateM = sunday.getMonth() + 1;
    let endDateD = sunday.getDate();

    startDateM = startDateM < 10 ? "0" + startDateM : startDateM;
    startDateD = startDateD < 10 ? "0" + startDateD : startDateD;
    endDateM = endDateM < 10 ? "0" + endDateM : endDateM;
    endDateD = endDateD < 10 ? "0" + endDateD : endDateD;

    let startData = `${startDateY}-${startDateM}-${startDateD}`;
    let endData = `${endDateY}-${endDateM}-${endDateD}`;
    return [startData, endData];
  },

  // 点击上一天或者下一天
  // getNextDate(date, day) {
  //   let dd = new Date(date);
  //   dd.setDate(dd.getDate() + day);
  //   let y = dd.getFullYear();
  //   let m =
  //     dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
  //   let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
  //   return y + "-" + m + "-" + d;
  // },

  // 下载文件
  downloadTemplate(url, name, axios) {
    if (!axios) axios = require("axios");
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = objectUrl;
        a.download = name;
        a.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        window.URL.revokeObjectURL(blob);
      });
  },

  //比较两个时间大小
  comparedate(date1, date2) {
    // console.log(date1);
    // console.log(date2);
    let oDate1 = new Date(date1);
    let oDate2 = new Date(date2);
    if (oDate1.getTime() == oDate2.getTime()) {
      return true;
    } else if (oDate1.getTime() > oDate2.getTime()) {
      return true;
    } else {
      return false;
    }
  },

  //比较两个时间大小 可以等于当前时间
  comparedate2(date1, date2) {
    // console.log(date1);
    // console.log(date2);
    let oDate1 = new Date(date1);
    let oDate2 = new Date(date2);
    if (oDate1.getTime() == oDate2.getTime()) {
      return false;
    } else if (oDate1.getTime() > oDate2.getTime()) {
      return true;
    } else {
      return false;
    }
  },

  // 下载文件
  exportFile({ url, data, method }) {
    let axios = require("axios");
    axios({
      method,
      url,
      data,
    })
      .then((res) => {
        if (res.code == 200) {
          location.href = process.env.VUE_APP_FILE_URL + res.result;
        }
      })
      .catch((err) => console.error("导出失败"));
  },

  getBase64(file) {
    return new Promise((resolve, reject) => {
      ///FileReader类就是专门用来读文件的
      const reader = new FileReader();
      //开始读文件
      //readAsDataURL: dataurl它的本质就是图片的二进制数据， 进行base64加密后形成的一个字符串，
      reader.readAsDataURL(file);
      // 成功和失败返回对应的信息，reader.result一个base64，可以直接使用
      reader.onload = () => resolve(reader.result);
      // 失败返回失败的信息
      reader.onerror = (error) => reject(error);
    });
  },

  //获得上周周一~周日的年月日
  getLastWeekData() {
    var lastweek = {};
    var date = new Date();
    // 上周一的日期
    date.setDate(date.getDate() - 7 - date.getDay() + 1);
    lastweek.start_day =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    // 上周日的日期
    date.setDate(date.getDate() + 6);
    lastweek.end_day =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return lastweek;
  },

  getNextMonth(date, day) {
    // date = date.split("/").join("-");
    let time = new Date(date);
    let y = time.getFullYear();
    let m = time.getMonth() + 1;
    if (day == 1) {
      if (m >= 12) {
        m = 1;
        y = Number(y) + 1;
      } else {
        m++;
      }
    } else {
      if (m == 1) {
        m = 12;
        y = Number(y) - 1;
      } else {
        m--;
      }
    }
    m = m < 10 ? `0${m}` : m;
    // console.log(String(y) + "/" + String(m));
    return String(y) + "-" + String(m);
  },

  // 获取月的第一天
  getCurrentMonthFirst(time) {
    // time = time.replace(/\//g, "-");
    let date = new Date(time);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let firstDay = new Date(year, month - 1, 1); //这个月的第一天
    let day = firstDay.getDate();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    return `${year}-${month}-${day}`;
  },

  // 获取月的最后一天
  getCurrentMonthLast(time) {
    // time = time.replace(/\//g, "-");
    let date = new Date(time);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let lastDay = new Date(year, month, 0); //是0而不是-
    let day = lastDay.getDate();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    return `${year}-${month}-${day}`;
  },

  // 判断是否在某个日期范围内
  isDuringDate(beginDateStr, endDateStr, target) {
    let curDate = new Date(target);
    let beginDate = new Date(beginDateStr);
    let endDate = new Date(endDateStr);
    // console.log(this.GetCurrentDate2("yyyy-mm-dd", beginDate));
    // console.log(this.GetCurrentDate2("yyyy-mm-dd", endDate));
    if (curDate >= beginDate && curDate <= endDate) {
      return true;
    }
    return false;
  },

  // 计算两个时间间隔是否满足需求
  CountTimeDiffer(time1, time2, num) {
    let stime = Date.parse(new Date(time1));
    let etime = Date.parse(new Date(time2));
    let usedTime = etime - stime;
    let days = Math.floor(usedTime / (24 * 3600 * 1000));
    let leave1 = usedTime % (24 * 3600 * 1000);
    let h = Math.floor(leave1 / (3600 * 1000));
    let leave2 = leave1 % (3600 * 1000);
    let m = Math.floor(leave2 / (60 * 1000));
    let over = Math.abs(days) + 1;
    let result = [];
    if (over > num) {
      result = [
        this.GetCurrentDate("yyyy-mm-dd"),
        this.GetCurrentDate("yyyy-mm-dd"),
      ];
    }
    return result;
  },

  dateComparison(paramsDateStr) {
    let bool;
    let time = new Date();
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let day = time.getDate(); // 获取日期(1-31)
    day = day < 10 ? "0" + day : day;
    let h = time.getHours(); //获取当前小时数(0-23)
    let m = time.getMinutes(); //获取当前分钟数(0-59)
    let s = time.getSeconds(); //获取当前秒数(0-59)
    if (h < 10) h = `0${h}`;
    if (m < 10) m = `0${m}`;
    if (s < 10) s = `0${s}`;
    let currentDateStr = `${year}/${month}/${day} ${h}:${m}:${s}`;
    if (currentDateStr == paramsDateStr) {
      bool = true;
    } else {
      let currentTime = new Date(currentDateStr).getTime();
      let paramsTime = new Date(paramsDateStr).getTime(); // 选择时间
      // 比较时间,如果大于参数时间,则选择的是过去时间
      if (currentTime > paramsTime) bool = true;
      else bool = false;
    }
    return bool;
  },

  getWeekDay(dateString, split, isZero) {
    if (isZero == undefined) isZero = true;
    split = split || "-";
    let dateStringReg = /^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/;
    if (dateString.match(dateStringReg)) {
      let presentDate = new Date(dateString),
        today = presentDate.getDay() !== 0 ? presentDate.getDay() : 7;
      return Array.from(new Array(7), function (val, index) {
        return formatDate(
          new Date(
            presentDate.getTime() - (today - index - 1) * 24 * 60 * 60 * 1000
          )
        );
      });
    } else {
    }

    function formatDate(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (isZero) {
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;
      }
      return `${year}${split}${month}${split}${day}`;
    }
  },
  isThisWeekOrLastWeek(startDate, endDate) {
    // 将日期字符串转换为日期对象
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);
    // 获取当前日期
    const today = new Date();
    const currentDay = today.getDay();

    const daysUntilThisWeekStart = currentDay === 0 ? 6 : currentDay - 1;
    const thisWeekStartDate = new Date(today);
    thisWeekStartDate.setDate(today.getDate() - daysUntilThisWeekStart);
    thisWeekStartDate.setHours(0, 0, 0, 0);

    const thisWeekEndDate = new Date(thisWeekStartDate);
    thisWeekEndDate.setDate(thisWeekEndDate.getDate() + 6);
    thisWeekEndDate.setHours(23, 59, 59, 999);

    const lastWeekStartDate = new Date(thisWeekStartDate);
    lastWeekStartDate.setDate(lastWeekStartDate.getDate() - 7);

    const lastWeekEndDate = new Date(lastWeekStartDate);
    lastWeekEndDate.setDate(lastWeekEndDate.getDate() + 6);
    lastWeekEndDate.setHours(23, 59, 59, 999);

    // 判断给定的时间范围是否在上周或本周

    if (startDateTime >= lastWeekStartDate && endDateTime <= lastWeekEndDate) {
      return "（上周）";
    } else if (
      startDateTime >= thisWeekStartDate &&
      endDateTime <= thisWeekEndDate
    ) {
      return "（本周）";
    } else {
      return undefined;
    }

    // 如果不是上周或本周则返回 undefined
    // return undefined;
  },
  // 给一个日期 获取出当前日期是星期几 返回英文缩写
  getDayOfWeek(dateString) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      return daysOfWeek[dayIndex];
  },
  //判断是否是本月和上月
  isThisMonthOrLastMonth(month) {
    const monthDate = new Date(month);
    const today = new Date();
    const thisMonthStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    thisMonthStartDate.setHours(0, 0, 0, 0);
    const lastMonthStartDate = new Date(thisMonthStartDate);
    lastMonthStartDate.setMonth(lastMonthStartDate.getMonth() - 1);
    if (monthDate >= lastMonthStartDate && monthDate < thisMonthStartDate) {
      return "（上月）";
    } else if (
      monthDate >= thisMonthStartDate &&
      monthDate < new Date(today.getFullYear(), today.getMonth() + 1, 1)
    ) {
      return "（本月）";
    }

    // If it doesn't fall into the current or previous month, return undefined
    return undefined;
  },
  // 判断当前时间是否在某个时间段内
  checkAuditTime(beginTime, endTime) {
    let nowDate = new Date();
    let beginDate = new Date(nowDate);
    let endDate = new Date(nowDate);

    let beginIndex = beginTime.lastIndexOf(":");
    let beginHour = beginTime.substring(0, beginIndex);
    let beginMinue = beginTime.substring(beginIndex + 1, beginTime.length);
    beginDate.setHours(beginHour, beginMinue, 0, 0);

    let endIndex = endTime.lastIndexOf(":");
    let endHour = endTime.substring(0, endIndex);
    let endMinue = endTime.substring(endIndex + 1, endTime.length);
    endDate.setHours(endHour, endMinue, 0, 0);
    return (
      nowDate.getTime() - beginDate.getTime() >= 0 &&
      nowDate.getTime() <= endDate.getTime()
    );
  },

  // 获取明天或者昨天
  getNextDate(date, day, split, isZero) {
    split = split || "-";
    let dd = date ? new Date(date) : new Date();
    dd.setDate(dd.getDate() + day);
    let y = dd.getFullYear();
    let m, d;
    // if (isZero) {
      m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    // } else {
    //   m = dd.getMonth() + 1;
    //   d = dd.getDate();
    // }
    return y + split + m + split + d;
  },

  // 判断某个日期是否是本周
  sameWeek(time) {
    // inDate 是一个date对象
    let pastTime = new Date(time).getTime();
    let today = new Date(new Date().toLocaleDateString());
    let day = today.getDay();
    day = day == 0 ? 7 : day;
    let oneDayTime = 60 * 60 * 24 * 1000;
    let monday = new Date(today.getTime() - oneDayTime * (day - 1));
    let nextMonday = new Date(today.getTime() + oneDayTime * (8 - day));
    if (monday.getTime() <= pastTime && nextMonday.getTime() > pastTime) {
      return true;
    } else {
      return false;
    }
    // let inDate = new Date(day)
    // let inDateStr = inDate.toLocaleDateString(); // 获取如YYYY/MM/DD的日期
    // let nowDate = new Date();
    // let nowTime = nowDate.getTime();
    // let nowDay = nowDate.getDay();
    // console.log(inDateStr);
    // console.log(nowTime, nowDay);
    // for (let i = 0; i < 7; i++) {
    //   if (inDateStr == (new Date(nowTime + (i - nowDay) * 24 * 3600 * 1000)).toLocaleDateString()) return true;
    // }
    // return false;
  },

  // 获取本周：选中当前日期 - 周日的日期
  // time: 通过传入的日期,获取传入日期的所在周
  // isCurrent: true:获取1~7的日期,false:获取传入日期到周日的日期
  getWeekTime(time, isCurrent) {
    let fun = (day, targetTime) => {
      let today = new Date(day); // 传入的日期
      today.setHours(0, 0, 0, 0);
      let targetDate = new Date(targetTime); // 需要对比的日期
      targetDate.setHours(0, 0, 0, 0);
      let bool;
      if (today.getTime() >= targetDate.getTime()) {
        bool = true;
      } else {
        bool = false;
      }
      return bool;
    };

    let getWeek = (date) => {
      let tiem = new Date(date);
      let weekZn = "";
      let weekIndex;
      let weekDay = ["日", "一", "二", "三", "四", "五", "六"];
      if (weekDay.indexOf(weekDay[tiem.getDay()]) > -1) {
        weekIndex = tiem.getDay() == 0 ? 7 : tiem.getDay();
        weekZn = weekDay[tiem.getDay()];
      }
      return {
        weekZn,
        weekIndex,
      };
    };

    let format = (time) => {
      let date = new Date(time);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      return `${year}/${month}/${day}`;
    };

    let new_Date = time ? new Date(time) : new Date(); //获取本周一周日期
    let timesStamp = new_Date.getTime();
    let currenDay = new_Date.getDay();
    let dates = [];
    for (let i = 0; i < 7; i++) {
      // let das = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString();
      // console.log(das);
      let das = format(
        new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - ((currenDay + 6) % 7)))
      );
      let data = {
        day: das,
        week: getWeek(das) || "",
      };
      das.replace(/[年月]/g, ".").replace(/[日上下午]/g, "");
      if (isCurrent) {
        dates.push(data);
      } else {
        let bool = fun(das, new_Date);
        if (bool) dates.push(data);
      }
    }
    return dates;
  },

  // 获取下周：选中下周一  - 下周日的日期
  nextWeek(time, split) {
    split = split || "-";
    let format = (time) => {
      let date = new Date(time);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      return `${year}${split}${month}${split}${day}`;
    };

    let new_Date = time ? new Date(time) : new Date(); //获取本周一周日期
    let timesStamp = new_Date.getTime();
    let currenDay = new_Date.getDay();
    let dates = [];
    for (let i = 0; i < 7; i++) {
      let das = new Date(
        timesStamp + 24 * 60 * 60 * 1000 * (i - ((currenDay + 6) % 7))
      ).toLocaleDateString();
      das.replace(/[年月]/g, ".").replace(/[日上下午]/g, "");
      dates.push(das);
    }
    let Sunday = dates[dates.length - 1];
    let dd = new Date(Sunday);
    dd.setDate(dd.getDate() + 1);
    let timesStamp2 = dd.getTime();
    let currenDay2 = dd.getDay();
    let week = [];
    for (let i = 0; i < 7; i++) {
      // let das = new Date(timesStamp2 + 24 * 60 * 60 * 1000 * (i - (currenDay2 + 6) % 7)).toLocaleDateString();
      let das = format(
        new Date(
          timesStamp2 + 24 * 60 * 60 * 1000 * (i - ((currenDay2 + 6) % 7))
        )
      );
      // console.log(das);
      das.replace(/[年月]/g, ".").replace(/[日上下午]/g, "");
      week.push(das);
    }
    return week;
  },

  // 获取本月：选中当前日期 - 本月最后一天
  // time: 通过传入的日期,获取传入日期所在月的所有日期
  // isCurrent: true:获取所有日期,false:获取传入日期到月底的日期
  getMonthTime(time, isCurrent, isZero) {
    let getDayNum = (time) => {
      let date = new Date(time);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let d = new Date(year, month, 0);
      return d.getDate();
    };

    let fun = (day, targetTime) => {
      let today = new Date(day); // 传入的日期
      today.setHours(0, 0, 0, 0);
      let targetDate = new Date(targetTime); // 需要对比的日期
      targetDate.setHours(0, 0, 0, 0);
      let bool;
      if (today.getTime() >= targetDate.getTime()) {
        bool = true;
      } else bool = false;
      return bool;
    };

    let format = (time) => {
      let date = new Date(time);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      return `${year}/${month}/${day}`;
    };

    let currentTime = format(new Date());
    let now = time ? new Date(time) : new Date();
    let dayLength = getDayNum(now);
    let list = [];
    for (let i = 1; i <= dayLength; i++) {
      let days = format(now.setDate(i));
      if (isCurrent) {
        list.push(days);
      } else {
        if (fun(days, currentTime)) {
          list.push(days);
        }
      }
    }
    return {
      days: list,
      month: currentTime,
    };
  },

  // 获取下月：选中下月一日至最后一天发日期
  getNextMonth(time, split) {
    split = split || "-";
    let getDayNum = (time) => {
      let date = new Date(time);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let d = new Date(year, month, 0);
      return d.getDate();
    };

    let format = (time) => {
      let date = new Date(time);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      return `${year}${split}${month}${split}${day}`;
    };

    let date = time ? new Date(time) : new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    month++;
    if (month == 13) {
      month = 1;
      year++;
    }
    let dayLength = getDayNum(`${year}/${month}/1`);
    let newDate = new Date(`${year}/${month}/1`);
    let list = [];
    for (let i = 1; i <= dayLength; i++) {
      let days = format(newDate.setDate(i));
      list.push(days);
    }
    return {
      days: list,
      month: `${year}-${month}`,
    };
  },
  //获取本月的第一天和最后一天
  getFirstAndLastDayOfMonth() {
    const currentDate = new Date();
    const firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // 格式化日期为 YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const firstDayFormatted = formatDate(firstDay);
    const lastDayFormatted = formatDate(lastDay);

    return {
      firstDay: firstDayFormatted,
      lastDay: lastDayFormatted,
    };
  },
  //判断选择时间是否在范围内
  isDateInRange(startDate, endDate, selectedDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    selectedDate = new Date(selectedDate);

    // 检查选择的时间是否在开始时间和结束时间之间
    return selectedDate >= startDate && selectedDate <= endDate;
  },
  //获取当前时间半个小时之后的时间
  getCurrentTimePlusHalfHour() {
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + 30 * 60 * 1000); // 30分钟后的时间

    // 格式化日期为 yyyy-mm-dd hh:mm
    const formatDateTime = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    return formatDateTime(newDate);
  },

  checkTimeDifference(timeArray) {
    const time1 = new Date(timeArray[0]);
    const time2 = new Date(timeArray[1]);

    // 计算时间差（毫秒数）
    const timeDifference = Math.abs(time2.getTime() - time1.getTime());

    // 计算时间差对应的天数
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    // 如果时间差超过14天，返回 false
    return daysDifference <= 14;
  },

  getLastWeekDates(date, split) {
    // split = split || "-";
    // let now = new Date(); // 当前日期
    // let lastWeek = [];
    // let oneDayInMillis = 24 * 60 * 60 * 1000; // 一天的毫秒数
    // for (let i = 1; i <= 7; i++) {
    //   let day = new Date(now.getTime() - i * oneDayInMillis);
    //   let time = day.toISOString().split("T")[0];
    //   time = time.replace(/-/g, split);
    //   lastWeek.push(time);
    // }
    // return lastWeek.reverse();
  },

  isThisOrLastMonth(month, year) {
    console.log(month);
    console.log(year);
    let now = new Date(); // 当前日期
    let currentMonth = now.getMonth() + 1; // 获取当前月份（注意月份是从0开始的）
    let currentYear = now.getFullYear(); // 获取当前年份
    if (year == currentYear && month == currentMonth) {
      return "本月";
    } else if (year == currentYear && month == currentMonth - 1) {
      return "上月";
    } else {
      return ""; // 其他月份
    }
  },
};
