import md5 from "md5";
import user from "../../../../../api/user.js";
import Verify from "../../../../../components/verifition/Verify";

export default {
  components: {
    Verify,
  },
  data() {
    return {
      forgetChecked: false,
      typepsd: "password",
      flag: true,
      isLoginClick: true,
      imgsrc: require("@/assets/images/denglu_ic_biyan.png"),
      ruleForm: {
        name: "",
        password: "",
        // number: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },

  created() {
    document.onkeydown = (e) => {
      let key = window.event.keyCode;
      if (key && key == 13) this.submitForm();
      // if (window.event === undefined) {
      // 	var key = e.keyCode
      // } else {
      // 	var key = window.event.keyCode
      // }
      // if (key === 13) {
      // 	this.submitForm()
      // }
    };
    this.getStorage();
  },
  methods: {
    success(data) {
      // let this_ = this;
      // // data 返回的二次验证参数
      // let param = this.buildingParams();
      // param.imgCode = data.captchaVerification;
      // if (param) {
      //   user.getTimestamp().then((res) => {
      //     var time = res[2];
      //     param.date = time;
      //     param.password = md5(
      //       md5(param.password).toUpperCase() + param.date
      //     ).toUpperCase();
      //     const self = this;
      //     user.userLogin(param).then((res) => {
      //       // console.log("---------");
      //       // console.log(res);
      //       if (self.forgetChecked == true) {
      //         self.setStorage(self.ruleForm.name, self.ruleForm.password, 7);
      //       } else self.clearStorage();

      //       if (res[0] == 0) {
      //         this_.$router.push({
      //           path: "/index",
      //         });
      //       } else {
      //         this_.$message.warning(res[1]);
      //       }
      //     });
      //   });
      // //当mode="pop"时,调用组件实例的show方法显示组件
      // this_.$refs.verify.show();
      // }

      let param = {
        userCode: this.ruleForm.name,
        password: this.ruleForm.password,
        isNeedValidate: 1,
        captchaVerification: data.captchaVerification,
        // imgCode: ""
      };
      this.isLoginClick = false;
      user.getTimestamp().then((res) => {
        this.isLoginClick = true;

        param.date = res[2];
        param.password = md5(
          md5(param.password).toUpperCase() + param.date
        ).toUpperCase();
        this.$axios({
          method: "post",
          url: "/system/login/loginWebCaptcha",
          data: param,
          // headers: {
          //   request_base_type: true,
          // },
        }).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("schoolId", res.data.schoolId);
            localStorage.setItem("userId", res.data.userId);

            if (this.forgetChecked) {
              this.setStorage(this.ruleForm.name, this.ruleForm.password, 7);
            } else {
              this.clearStorage();
            }
            if (res.data.userDingtalkList.length == 1) {
              this.$store.state.tabsPage = [
                {
                  title: "控制台",
                  name: "/base/main/desk/desk",
                },
              ];
              this.$store.state.TabsValue = "/base/main/desk/desk";
              this.$store.state.noticeShow = true;
              this.$router.push({
                path: "/base/main/desk/desk",
              });
            } else if (res.data.userDingtalkList.length > 1) {
              let string = JSON.stringify(res.data.userDingtalkList);
              this.$emit("selectsc", string);
            } else if (res.data.userDingtalkList.length == 0) {
              this.$message.error("暂无使用权限，请联系管理员");
            }
          } else this.$message.error(res.describe);
        });
        return;
        user
          .userLogin(param)
          .then((res) => {
            localStorage.setItem(
              "schoolId",
              res[2].schoolId == "null" ? "" : res[2].schoolId
            );
            if (this.forgetChecked) {
              this.setStorage(this.ruleForm.name, this.ruleForm.password, 7);
            } else {
              this.clearStorage();
            }
            if (res[0] == 200) {
              if (res[2].userDingtalkList.length == 1) {
                this.$store.state.tabsPage = [
                  {
                    title: "控制台",
                    name: "/base/main/desk/desk",
                  },
                ];
                this.$store.state.TabsValue = "/base/main/desk/desk";
                this.$store.state.noticeShow = true;
                this.$router.push({
                  path: "/base/main/desk/desk",
                });
              } else if (res[2].userDingtalkList.length > 1) {
                let string = JSON.stringify(res[2].userDingtalkList);
                this.$emit("selectsc", string);
              } else {
              }
            } else {
              this.$message.warning(res[1]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
      // } else {
      //   setTimeout(() => {
      //     this.isLoginClick = true;
      //   }, 1500);
      // }
    },
    buildingParams() {
      let params = {
        userCode: this.ruleForm.name,
        password: this.ruleForm.password,
        isNeedValidate: 1,
        // imgCode: ""
      };
      return params;
    },
    handleclose() {
      this.flag = !this.flag;
      if (this.flag) {
        this.typepsd = "password";
        this.imgsrc = require("@/assets/images/denglu_ic_biyan.png");
      } else {
        this.typepsd = "text";
        this.imgsrc = require("@/assets/images/denglu_ic_zhangkai.png");
      }
    },
    handleForget() {
      this.$router.push("/base/main/forgetPsd/forgetPsd");
    },

    //  确认登录
    submitForm(formName) {
      this.$refs.login_form.validate((valid, fields) => {
        if (valid && this.isLoginClick) {
          this.$refs.verify.show();
          // console.warn("可以点击登录了");
        }
      });
    },
    resetForm(formName) {
      // debugger
      this.$refs[formName].resetFields();
    },
    // changeImg(){
    //   this.imgUrl=this.BASEPATH+"/system/user/imgcode.htm?d="+new Date().getTime();
    //   this.ruleForm.number=""
    // },
    //设置Storage
    setStorage(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      localStorage.setItem("name", c_name);
      localStorage.setItem("password", c_pwd);
    },
    //读取Storage
    getStorage: function () {
      if (localStorage.getItem("name")) {
        this.ruleForm.name = localStorage.getItem("name");
        this.ruleForm.password = localStorage.getItem("password");
        this.forgetChecked = true;
      }
    },
    //清除Storage
    clearStorage: function () {
      localStorage.removeItem("name");
      localStorage.removeItem("password");
    },
  },
};
