/*
 * @Author: Lijiahui
 * @Date: 2020-10-16 11:11:52
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2024-03-13 11:32:22
 */

import axios from "axios";
import { ElMessage } from "element-plus";
import { API } from "@commons/utils/constants";
import { processParams } from "@utils/axios.helper";
import Storage from "@utils/storage";
import router from "vue-router";
import { LoginRouter } from "../router/base.router";
var count;

export const requestInterceptors = (config) => {
  //重写请求头
  // setHeader(config.headers)
  // 判断当前接口是否是用来下载文件
  if (config.url.includes("uplad")) {
    config.baseURL = "";
  }
  if (config.url.includes("downloadFile")) {
    config.baseURL = "";
  }
  if (config.method.toLowerCase() === "post") {
    config.data = config.data;
    // config.data = processParams(config.data);
  } else {
    config.params = config.params;
    // config.params = processParams(config.params);
  }

  return config;
};
export default (router) => {
  axios.defaults.baseURL = API;
  axios.interceptors.request.use(requestInterceptors);
  axios.interceptors.response.use(
    (res) => {
      switch (res.data.code) {
        case 200:
          break;
        case 11001:
        case 11002:
        case 11003:
        case 11004:
          Storage.removeAll();
          router.push({ name: LoginRouter.name });
          break;
        case 3000:
          ElMessage({
            message: res.data.describe,
            type: "error",
          });
          break;
        // case 5004:
        //   ElMessage({
        //     message: res.data.describe,
        //     type: "error",
        //   });
        //   break;
        case 5002:
          ElMessage({
            message: "登录过期,请重新登录",
            type: "error",
          });
          let backlen = window.history.length - 1;
          window.history.go(-backlen);
          router.replace("/");
          break;
        default:
          break;
      }
      res.data.responseHeaders = res.headers;
      return res.data;
    },
    (error) => {
      let countClear = (message) => {
        count && clearTimeout(count);
        count = setTimeout(() => {
          // console.log(ElementPlus);
          // Message.error(message);
        }, 500);
      };
      switch (error.response && error.response.code) {
        case "500":
          countClear("出错了,请稍后重试");
          break;
        case "404":
          countClear("未找到数据");
          break;
        case "422":
          break;
        default:
          countClear(error.response.data.message);
          break;
      }

      return Promise.reject(error);
    }
  );
};
