/*
 * @Author: yuan wei lin
 * @Date: 2022-10-09 13:46:47
 * @LastEditors: yuan wei lin
 * @LastEditTime: 2023-04-10 13:40:31
 * @Descripttion: 
 */
// import Vue from 'vue';
// import Vuex from 'vuex';
import { createStore } from 'vuex'
// 引入 router 在vuex内使用router跳转
import router from '../router';

// Vue.use(Vuex);
// Vue.use(router);

export default new createStore({
  state: { // 定义tabs 所需参数
    tabsPage: [
      { title: '控制台', name: '/base/main/desk/desk' },
    ],
    copeId: "",
    TabsValue: '/base/main/desk/desk',

    noticeShow: true,
    // initTable: false
  },

  mutations: {
    // noticeIfshow(state, data) {
    //   state.noticeShow = true
    // },
    // CHANGE_INITTABLE_STATE(state, data) {
    //   state.initTable = data
    // },
    editableTabs: (state, obj) => {
      // 浅拷贝 state.tabsPage
      const arr = Array.from(state.tabsPage)
      state.noticeShow = false;
      // 判断数组内是否为空
      if (arr.length !== 0) {
        // 使用 Array.some 去判断是否存在对象信息
        var even = function (obj) {
          return arr.some(item => {
            return item.name === obj.link;
          });
        }
        if (!even(obj)) { /* 如果不存在将对象push进数组内bing */
          arr.push({ title: obj.name, name: obj.link, id: obj.query.id })
          state.tabsPage = arr;
          state.TabsValue = obj.link;
          // if (obj.query) {
          //   router.push({ name: obj.link, query: obj.query });
          // } else {
          //   router.push({ name: obj.link });
          // }
        } else { // 如果存在 只做跳转选中
          state.tabsPage.forEach((item) => {
            if (item.name == obj.link) {
              item.title = obj.name
            }
          })
          state.TabsValue = obj.link;
          // if(obj.query){
          //   router.push({ name: obj.link,query:obj.query });
          // }else{
          //   router.push({ name: obj.link });
          // }
        }
      } else { // 如果为0
        arr.push({
          title: obj.name, name: obj.link, id: obj.query.id
        })
        state.tabsPage = arr;
        state.TabsValue = obj.link;
        // if(obj.query){
        //   router.push({ name: obj.link,query:obj.query });
        // }else{
        //   router.push({ name: obj.link });
        // }
      }
      // console.log(state.tabsPage)
    }
  },
  actions: {
    // 注册方法
    editableTabs(context, obj) {
      context.commit('editableTabs', obj)
    }
  }
})
